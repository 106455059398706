<template>
  <base-popup
    title="Настройка группировки"
    :persistent="false"
    onlyClose
    v-model="dialog"
  >
    <template slot="actions">
      <v-btn dark color="blue" outlined @click="resetHeaders"
        >Сбросить группировку</v-btn
      >
    </template>
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <base-table
      v-if="items"
      item-key="value"
      hide-default-footer
      :headers="headers"
      :items="items.filter((e) => e.value !== 'actions' && !e.notGroup)"
      class="mt-3"
      :showSettings="false"
      notShowSelect
    >
      <template v-slot:body="scope">
        <tbody>
          <tr v-for="item in scope.items" :key="item.value">
            <td>
              <v-simple-checkbox
                :value="
                  selected &&
                  selected.value !== null &&
                  item.value === selected.value
                "
                :ripple="false"
                @input="$event ? (selected = item) : (selected = null)"
              />
            </td>
            <td>{{ item.text }}</td>
            <td class="text-no-wrap">
              <v-switch
                v-if="
                  selected &&
                  selected.value !== null &&
                  item.value === selected.value
                "
                v-model="selected.showGroupName"
                :label="selected.showGroupName ? 'Показывать' : 'Не показывать'"
              ></v-switch>
              <v-switch v-else disabled></v-switch>
            </td>
          </tr>
        </tbody>
      </template>
    </base-table>
  </base-popup>
</template>
<script>
import { findParent } from "@/utils/DomVueHelper";
import BasePopup from "@/layouts/BasePopup.vue";
import BaseTable from "@/components/base/BaseTable.vue";

import overlayable from "vuetify/lib/mixins/overlayable";

export default {
  name: "popup-edit-grouping",
  components: {
    BasePopup,
    BaseTable,
  },
  mixins: [overlayable],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
    groupBy: {
      type: [String, Array],
      default: null,
    },
  },
  data() {
    return {
      drag: false,
      isInit: false,
      headers: [
        {
          value: "select",
          text: "",
          sortable: false,
          width: "1%",
        },
        {
          value: "text",
          text: "Столбец",
          sortable: false,
          width: "100%",
        },
        {
          value: "showGroupName",
          text: "Название столбца",
          sortable: false,
        },
      ],
      selected: { value: null },
      list: [],
      defaultHeaders: null,
    };
  },
  computed: {
    originHeaders() {
      return findParent(
        this,
        (e) => {
          return (
            e?.$options?.name !== "popup-edit-grouping" &&
            e?.headers &&
            !e.$options?.propsData?.headers
          );
        },
        20
      );
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    nameData() {
      if (this?.originHeaders) {
        const cName =
          this.originHeaders.$options._componentTag ??
          this.originHeaders.$options.name;
        return "table.group." + cName;
      }
      return null;
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        this.$emit("update:groupBy", val?.value ?? null);
        if (this.dialog) this.updateHeaderData();
      },
    },
  },
  created() {
    const item = this.originHeaders;
    if (!localStorage.getItem(this.nameData)) {
      const header = item?.headers?.find((e) => e.defaultGroup);
      if (header) {
        this.selected = header ?? {};
        this.updateHeaderData();
      }
    }

    this.init();
  },
  methods: {
    resetHeaders() {
      const item = this.originHeaders;

      const header = item?.headers?.find((e) => e.defaultGroup);

      this.selected = header ?? null;
      this.updateHeaderData();
    },
    updateHeaderData() {
      const item = this.originHeaders;
      if (item?.$options?._componentTag || item.$options.name) {
        localStorage.setItem(this.nameData, JSON.stringify(this.selected));
      }
    },

    init() {
      if (this.isInit) return;
      this.isInit = true;

      const tmp = JSON.parse(localStorage.getItem(this.nameData));
      const item = this.originHeaders;
      if (!item) return;

      for (let i = 0; i < item.headers.length; i++) {
        const data = tmp && tmp.value === item.headers[i].value ? tmp : {};
        this.$set(
          item.headers[i],
          "showGroupName",
          data?.showGroupName ?? item.headers[i]?.showGroupName ?? true
        );
      }

      if (tmp) this.selected = tmp;
    },
  },
};
</script>
<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
